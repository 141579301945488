import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import './style.scss'
import Sidebar from '../Sidebar'
import Helmet from 'react-helmet'

class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark

    const { category } = post.frontmatter
    const { tagSlugs, categorySlug } = post.fields

    const featuredImgSize = post.frontmatter.featuredImage.childImageSharp.sizes.src
    const path = post.frontmatter;
    const postpath = `https://blog.itamaev2.com/${path}`
    const postDescription = post.frontmatter
    const image = `https://blog.itamaev2.com/${featuredImgSize}`

    const { previous, next } = this.props.pageContext

    const homeBlock = (
      <div>
        <Link className="post-single__home-button" to="/">
          All Articles
        </Link>
      </div>
    )

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tagSlugs &&
            tagSlugs.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
    )

    return (
      <div>
        <Helmet>
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={post.frontmatter.title} />
          <meta name="twitter:image" content={image} />
          <meta name="twitter:description" content={postDescription} />
          <meta name="twitter:site" content="@KinnikuMeganeDe" />
          
          <meta property="og:title" content={post.frontmatter.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={image} />
          <meta property="og:url" content={postpath} />
          <meta property="og:description" content={postDescription} />
        </Helmet>
        <div>
          {homeBlock}
          <div className="post-single">
            <div className="post-single__inner">
              <div className="post-single__date">
                {moment(post.frontmatter.date).format('YYYY/M/DD')}
              </div>
              <div className="post-single__pankuzu">
                  <span className="post-single__pankuzu_home-in-pankuzu"><Link to="/">ホーム</Link></span>
                  <span className="post-single__pankuzu_firsticon"></span>
                  <span className="post-single__pankuzu_category-in-pankuzu"><Link to={categorySlug}>{category}</Link></span>
                  <span className="post-single__pankuzu_firsticon"></span>
                  <span className="post-single__pankuzu_title">{post.frontmatter.title}</span>
              </div>
              <h1 className="post-single__title">{post.frontmatter.title}</h1>
              <div
                className="post-single__body"
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
            </div>
            <div className="post-single__footer">
              <a href="https://twitter.com/share?ref_src=twsrc%5Etfw" className="twitter-share-button" data-show-count="false">Tweet</a>
              {tagsBlock}
              <ul
                style={{
                    display: `flex`,
                    flexWrap: `wrap`,
                    justifyContent: `space-between`,
                    listStyle: `none`,
                    padding: 0,
                    marginTop: 60,
                }}
                className="previousNext"
              >
                <li className={next ? '' : 'disabled'}>
                    {next&& (
                        <Link to={next.fields.slug} rel="next">
                            ←　新しい記事
                        </Link>
                    )}
                </li>
                <li className={previous ? '' : 'disabled'}>
                    {previous && (
                        <Link to={previous.fields.slug} rel="prev">
                            古い記事　→
                        </Link>
                    )}
                </li>
              </ul>
              <hr />
              <p className="post-single__footer-text">
                {subtitle}
                <a
                  href={`https://twitter.com/${author.twitter}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <br /> <strong>{author.name}</strong> on Twitter
                </a>
              </p>
              {commentsBlock}
            </div>
          </div>
          <Sidebar {...this.props} />
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
